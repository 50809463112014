function redirectToClientLangPage(lang) {
  // do not redirect from single role pages
  // if (RegExp(/\d*\/careers\/open-roles\/\d{1,}/).test(window.location.pathname)) return

  const noLangPath = window.location.pathname.replace(/\/zh|pt\//g, '')
  const langPath = lang ? '/' + lang : ''
  const queryString = window.location.search
  const newPath = window.origin + langPath + noLangPath + queryString

  if (newPath !== window.location.href) {
    window.location.replace(newPath)
  }
}

function compareClientLanguageAndRedirect(siteLangs, prefferedLangs) {
  const [firstLang, ...rest] = prefferedLangs
  if (!firstLang) return

  let clientMatchPreffered = false
  let currentIndex = 0

  while (!clientMatchPreffered && currentIndex < siteLangs.length) {
    const siteLang = siteLangs[currentIndex]

    if (typeof siteLang === 'string' && firstLang === siteLang) {
      redirectToClientLangPage(firstLang)
      clientMatchPreffered = true
    } else if (Array.isArray(siteLang) && siteLang[1].includes(firstLang)) {
      redirectToClientLangPage(siteLang[0])
      clientMatchPreffered = true
    }
    currentIndex++
  }

  if (!clientMatchPreffered) { return compareClientLanguageAndRedirect(siteLangs, rest) }
}

const key = 'cLang'
function getCachedLangChoice() {
  return window.localStorage.getItem(key)
}

function cacheSelectedLang(lang) {
  lang = lang === 'default' ? '' : lang
  window.localStorage.setItem(key, lang)
}

module.exports = {
  redirectToClientLangPage,
  compareClientLanguageAndRedirect,
  getCachedLangChoice,
  cacheSelectedLang,
}
