import React from 'react'
import { HeadProvider } from 'react-head'

export const wrapRootElement = ({ element }) => {
  return <HeadProvider>{element}</HeadProvider>
}

const {
  redirectToClientLangPage,
  compareClientLanguageAndRedirect,
  getCachedLangChoice,
} = require('./src/lib/lang-utils')

const siteLangs = [
  ['', ['en', 'en-in', 'en-au', 'en-ca', 'en-nz', 'en-za', 'en-us', 'en-gb']],
  ['pt', ['pt', 'pt-br', 'pt-pt']],
  ['zh', ['zh', 'zh-cn', 'zh-hk', 'zh-tw']],
]

export const onClientEntry = () => {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistrations('/').then(registrations => {
      registrations.map(reg => reg.unregister())
    })
  }

  // do not perform lang comparision on storyblok preview
  if (!window.location.pathname.startsWith('/editor')) {
    // language comparison
    const cachedLangKey = getCachedLangChoice()
    if (cachedLangKey) {
      redirectToClientLangPage(cachedLangKey)
    } else {
      const prefferedLangs =
        navigator.languages &&
        navigator.languages.map(lang => lang.toLowerCase())
      compareClientLanguageAndRedirect(siteLangs, prefferedLangs)
    }
  }

  // reload page after redirection from go.pardot
  if (document.referrer && document.referrer.includes('//go.pardot.com/')) {
    window.location.replace(window.location.href)
  }

  window.isMobile = detectmob()

  function detectmob() {
    if (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i)
    ) {
      return true
    } else {
      return false
    }
  }

  // Load polyfill jquery to support HubSpot Forms
  // window.jQuery =
  //   window.jQuery ||
  //   (() => ({
  //     serializeArray: () => {},
  //   }))
}

export const onInitialClientRender = () => {
  require('typeface-montserrat')
  var smoothscroll = require('smoothscroll-polyfill')
  smoothscroll.polyfill()
}
